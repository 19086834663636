.programs{
display: flex;
flex-direction: column;
gap: 2rem;
padding: 0 2rem;
}

.programs-header{
display: flex;
gap: 4rem;
font-weight: bold;
justify-content: center;
color: white;
text-transform: uppercase;
font-size: 3rem;
font-style: italic;
  
}

.program-categories{
display: flex;
gap: 1rem;
}
.category{
display: flex;
flex-direction: column;
background-color: var(--gray);
gap: 1rem;
padding: 2rem;
color: white;
justify-content: space-between;

}
.category>:nth-child(1){
    width: 2rem;
    fill: white;
    height: 2rem;
}
.category>:nth-child(2){
  
    font-size: 1rem;
    font-weight: bold;
}
.category>:nth-child(3){
 
    font-size: 0.9rem;
    line-height: 25px;
}

.join-now{
    display: flex;
    gap: 2rem;
    align-items: center; 
}
.join-now>img{
    width: 1rem;
}
.category:hover{
    cursor: pointer;
    background: var(--planCard);
}
@media screen and (max-width: 768px) {
    .programs-header{
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    flex-direction: column;
    gap: 1rem;
   font-size: x-large;
        } 
        
.program-categories{
      
        flex-direction: column;
        }
}
    
