.footer{
    position : relative;
}
.footer>hr{
    border: 1px solid var(--lightgray);
}
.foot{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;


}
.social-links{
 display: flex;
 gap: 4rem;
  
}
.social-links>img{
    height: 2rem;
    cursor: pointer;
      width: 2rem;
}
.logo>img{
    width: 10rem;
}
.last-blur-1{
    right: 15%;
    width: 26rem;
    bottom: 0;
      height: 12rem;
      filter: blur(200px);
      background: red;

}
.last-blur-2{
    left: 15%;
    width: 26rem;
    bottom: 0;
      height: 12rem;
      filter: blur(200px);
      background: rgb(255, 115,0);
}
@media screen and (max-width: 768px) {
    .foot{
  
        height: 15rem;
    
    
    }   
}
