.testimonial{
display: flex;
gap: 1rem;
padding: 0 2rem;
}
.testimonial-l{
display: flex;
flex: 1 1;
gap: 2rem;
flex-direction: column;
text-transform: uppercase;
color: #fff;
}
.testimonial-l>:nth-child(1){
    color: var(--orange);
    font-weight: bolder;
    font-size: 1rem;

}
.testimonial-l>:nth-child(2){
font-weight: bold;
font-size: 3rem;
}
.testimonial-l>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}
.testimonial-l>:nth-child(4){
text-transform: none;
text-align: justify;
letter-spacing: 2px;
line-height: 40px;
}
.testimonial-r{
    position: relative;
    flex: 1 1;
}
.testimonial-r>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
} 
.testimonial-r>:nth-child(1){
    position: absolute;
    width:17rem;
    height: 20rem;
    border: 2px solid orange;
    background-color: transparent;
    right: 9rem;
    top: 0.9rem;


}
.testimonial-r>:nth-child(2){
    position: absolute;
    width:17rem;
    height: 19rem;
   
    background: var(--planCard);
    right: 7rem;
    top: 4rem;
}

.arrow{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 3rem;

} 
.arrow>img{
    width: 1.5rem;
    cursor: pointer;
}
@media screen and (max-width: 768px){
    .testimonial{
      flex-direction: column;
        }
  
       
            .testimonial-l>:nth-child(2){
            
            font-size: xx-large;
            }
            .testimonial-l>:nth-child(3){
                font-size: xx-large;
            }
            .testimonial-l>:nth-child(4){
       font-size: 1rem;
            }
            .testimonial-r{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;
            justify-content: center;
            }

            .testimonial-r>img{
                position: relative;
              align-self: center;
                right: 0rem;
                top: 0rem;
            } 
            .testimonial-r>div{
                position: relative;
            display: none;
               }
             .testimonial-r>:last-child{
                display: block;
                bottom: 0;
                left:0;
             }
        }